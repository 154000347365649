//
// @file
// This file includes all classes related to the box model
//
.pos-relative {
  position: relative;
}

.inline-block {
  @extend %inline-block;
}

.inline-block--top {
  @extend %inline-block--top;
}

.inline-block--middle {
  @extend %inline-block--middle;
}
